<template>
  <div class="home">
    <Dashboard />
    <Media />
    <VerifyAndReceive />
    <Solution />
    <UseCase />
    <APIIntro />
    <!-- <Testimonials /> -->
    <GetStarted />
  </div>
</template>

<script>
import Dashboard from "../components/HomeComponents/Dashboard.vue";
import Media from "../components/HomeComponents/MediaFeatured.vue";
import VerifyAndReceive from "../components/VerifyAndReceive.vue";
import Solution from "../components/HomeComponents/Solution.vue";
import UseCase from "../components/HomeComponents/UseCase.vue";
import APIIntro from "../components/HomeComponents/APIIntro.vue";
// import Testimonials from "../components/Testimonials.vue";
import GetStarted from "../components/GetStarted";

export default {
  name: "Home",
  components: {
    Dashboard,
    Media,
    VerifyAndReceive,
    Solution,
    UseCase,
    APIIntro,
    // Testimonials,
    GetStarted,
  },
  data() {
    return {
      title: "Powering digital payments and identity verification",
      description:
        "Accept online payments with card, FPX and e-Wallet in less than a minute using Tekkis no-code solutions. No Tech team or website needed. Tekkis also offers API integrations for both eKYC and online payments.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Accept online payments with card, FPX and e-Wallet in less than a minute using Tekkis no-code solutions. No Tech team or website needed. Tekkis also offers API integrations for both eKYC and online payments.";
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
