<template>
  <div id="app">
    <div ref="freshdeck"></div>
    <NewsBanner />
    <div v-if="showDefNavBar"><Header /></div>
    <div v-if="showInsNavBar"><HeaderInsurance :showCover="buttonCover" /></div>
    <router-view />
    <Footer />
  </div>
</template>
<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/app.scss";
@import "src/assets/styles/all.scss";

#app {
  font-family: Jost, Helvetica, Arial, sans-serif;
}

#nav {
  padding: 30px;
}
</style>

<script>
import Header from "./components/Header";
import HeaderInsurance from "./components/HeaderInsurance";
import Footer from "./components/Footer";
import NewsBanner from "./components/NewsBanner.vue";

export default {
  name: "App",
  components: {
    Header,
    HeaderInsurance,
    Footer,
    NewsBanner,
  },
  data() {
    return {
      showDefNavBar: true,
      showInsNavBar: false,
      buttonCover: true,
    };
  },
  watch: {
    $route() {
      // console.log(this.$route.name);
      if (
        this.$route.name === "cyberinsurance" ||
        this.$route.name === "cyberinsuranceurl" ||
        this.$route.name === "cyberinsurance-pay" ||
        this.$route.name === "cyberinsurance-status"
      ) {
        this.showDefNavBar = false;
        this.showInsNavBar = true;
      } else {
        this.showDefNavBar = true;
        this.showInsNavBar = false;
      }

      if (this.$route.name === "cyberinsurance-status") {
        this.buttonCover = false;
      } else {
        this.buttonCover = true;
      }
    },
  },
};
</script>
