<template>
  <div class="custom-card mt-4">
    <b-container
      class="text-left padding-card d-flex flex-column justify-content-between"
    >
      <div>
        <!-- <img
          class="titleimg"
          :src="require(`@/assets/img/${Card.titleimg}`)"
          alt=""
        /> -->
        <h5 class="title-card" v-html="Card.title"></h5>
        <p class="pt-3 pb-2 card-p" v-html="Card.description"></p>
      </div>

      <b-button
        v-if="!Card.commingSoon"
        pill
        class="btn-tekkis btn-hover text-700-22 btn btn-secondary btn-sm rounded-pills mt-4"
        :to="'/' + Card.link"
        :v-scroll-to="Card.scroll"
        style="font-size: 18px; width: 190px; padding: 10px 25px 10px 25px"
        >{{ Card.btntitle }}
        <img
          class="img-fit-div"
          style="width: 15px"
          src="@/assets/img/arrow-right-white.png"
          alt=""
      /></b-button>

      <b-button
        v-if="Card.commingSoon"
        pill
        class="btn-disabled btn-hover text-700-22 btn btn-sm rounded-pills mt-4"
        :v-scroll-to="Card.scroll"
        style="font-size: 18px; width: 190px; padding: 10px 25px 10px 25px"
        >Coming Soon</b-button
      >
    </b-container>
    <img
      class="solution-img"
      :src="require(`@/assets/img/${Card.image}`)"
      style="width: 100%"
      alt=""
    />
  </div>
</template>

<style lang="scss">
@import "src/assets/styles/all.scss";
.solution-img {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.padding-card {
  height: 429px;
  padding: 2rem 2rem;
  justify-content: center;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: #fefefe;
  -webkit-box-shadow: 0px 0px 35px 1px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 35px 1px rgba(0, 0, 0, 0.04);
  transition: 0.4s;
}
.padding-card:hover {
  -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1201px) {
  .padding-card {
    height: auto;
    padding: 2rem;
  }
}
.title-card {
  color: $pink-500;
}
.btn-disabled {
  background-color: #efefef;
  color: #bdbdbd;
  border: 1px solid #efefef;
}
</style>

<script>
// import TekkisButton from "./buttons/TekkisButton.vue";

export default {
  components: {
    // TekkisButton,
  },
  props: {
    Card: Object,
  },
};
</script>
