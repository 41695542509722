<template>
  <div class="div-padding hero-images pt-lg-5">
    <b-container class="px-md-5 pt-5 wrapper">
      <b-row align-h="center" class="we-verify-text pt-3 text-center">
        <h4
          class="verify-text"
          v-html="$t('website.homepage.verifyandreceive.title')"
        ></h4>
      </b-row>
      <b-row align-h="center" id="trigger1">
        <img
          id="animate1"
          class="img-fit-div1"
          src="@/assets/img/verify-customer.png"
          alt="verify-customer"
        />
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
$large: 992px;
.hero-images {
  // background-image: url("../assets/img/dots.png");
  background: url("../assets/img/dots.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.div-padding {
  padding: 0% 5%;
}

.we-verify-text {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 52px;
}

.verify-text {
  font-size: 1.5rem;
  line-height: 5rem;
  @media screen and (min-width: 576px) {
    font-size: 2rem;
  }
}

.img-fit-div1 {
  max-width: 100%;
  @media screen and (min-width: 576px) {
    width: 45%;
  }
}
</style>

<script>
export default {
  computed: {
    msg() {
      return `${this.$t(
        "website.homepage.verifyandreceive.title1"
      )} <span style="border-bottom: 12px solid rgb(255, 51, 119)
            >${this.$t("website.homepage.verifyandreceive.title2")}</span
          > ${this.$t("website.homepage.verifyandreceive.title3")}`;
    },
  },
};
</script>
