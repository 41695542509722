import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "/home",
    component: Home,
    meta: {
      title: "Malaysian eKYC and online payment processing | tekkis",
      metaTags: [
        {
          name: "description",
          content:
            "tekkis’ suite of APIs power digital identity verification and online payment. Use tekkis’ solutions to fight fraud, securely onboard, and accept payments from customers.",
        },
        {
          property: "og:description",
          content:
            "tekkis’ suite of APIs power digital identity verification and online payment. Use tekkis’ solutions to fight fraud, securely onboard, and accept payments from customers.",
        },
      ],
    },
  },
  // {
  //   path: "/alumni",
  //   name: "alumni",
  //   component: () => import("../views/Alumni.vue"),
  // },
  {
    path: "/blog",
    beforeEnter() {
      window.location.href = "https://blog.tekkis.com.my/";
    },
  },
  {
    path: "/thankyou",
    name: "thankyou",
    component: () => import("../views/Thankyou.vue"),
  },
  {
    path: "/solutions-tpay",
    name: "solutions-tpay",
    component: () => import("../views/SolutionstPay.vue"),
    meta: {
      metaTags: [
        {
          name: "description",
          content:
            "Help customers make an online payment with you in less than a minute! All payment solutions include a built-in checkout page for immediate payment collection from your customers. No website building or coding skills required.",
        },
        {
          property: "og:description",
          content:
            "Help customers make an online payment with you in less than a minute! All payment solutions include a built-in checkout page for immediate payment collection from your customers. No website building or coding skills required.",
        },
      ],
    },
  },
  {
    path: "/solutions-tverify",
    name: "solutions-tverify",
    component: () => import("../views/Solutions.vue"),
  },
  {
    path: "/usecases-tpay",
    name: "usecases-tpay",
    component: () => import("../views/Usecases-tpay.vue"),
  },
  {
    path: "/usecases-tverify",
    name: "usecases-tverify",
    component: () => import("../views/Usecases.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/Launch.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/Faq.vue"),
  },
  {
    path: "/company",
    name: "company",
    component: () => import("../views/Company.vue"),
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () => import("../views/ContactUs.vue"),
  },
  {
    path: "/ourteam",
    name: "ourteam",
    component: () => import("../views/OurTeam.vue"),
  },
  {
    path: "/career",
    name: "career",
    component: () => import("../views/Career.vue"),
  },
  {
    path: "/pricing-tpay",
    name: "pricing-tpay",
    component: () => import("../views/PricingtPay.vue"),
  },
  {
    path: "/pricing-tverify",
    name: "pricing-tverify",
    component: () => import("../views/Pricing.vue"),
  },
  {
    path: "/termscondition",
    name: "termscondition",
    component: () => import("../views/TermsCondition.vue"),
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/cyberinsurance",
    name: "cyberinsurance",
    component: () => import("../views/CyberInsurance.vue"),
  },
  {
    path: "/cyberinsuranceurl/:name?/:email?/:phone?",
    name: "cyberinsuranceurl",
    component: () => import("../views/CyberInsurance.vue"),
  },
  {
    path: "/cyberinsurance-pay", // Parameter ':jsonData' to receive JSON data (with '?', parameter is optional)
    name: "cyberinsurance-pay",
    component: () => import("../views/CyberInsurancePay.vue"),
  },
  {
    path: "/cyberinsurance-status",
    name: "cyberinsurance-status",
    component: () => import("../views/CyberInsuranceStatus.vue"),
  },
  // {
  //   path: "/404",
  //   name: "404",
  //   component: () => import("../views/404.vue"),
  // },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    name: "root",
    component: Home,
    // component: () => import("../views/CyberInsurance.vue"),
    meta: {
      title: "Malaysian eKYC and online payment processing | tekkis",
      metaTags: [
        {
          name: "description",
          content:
            "tekkis’ suite of APIs power digital identity verification and online payment. Use tekkis’ solutions to fight fraud, securely onboard, and accept payments from customers.",
        },
        {
          property: "og:description",
          content:
            "tekkis’ suite of APIs power digital identity verification and online payment. Use tekkis’ solutions to fight fraud, securely onboard, and accept payments from customers.",
        },
      ],
    },
  },
];

const router = new VueRouter({
  mode: "history",

  scrollBehavior: function (to) {
    if (to) {
      return {
        el: to,
        behavior: "smooth",
      };
    }
  },
  routes,
});

export default router;
