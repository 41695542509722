<template>
  <div class="background-black white py-5">
    <b-container class="text-center p-2">
      <div class="mb-4">
        <span class="text-1">{{
          $t("website.homepage.getstarted.title")
        }}</span>
        <p>{{ $t("website.homepage.getstarted.subtitle") }}</p>
      </div>
      <b-row align-h="center" align-v="center">
        <b-col lg="3" class="pr-lg-0">
          <b-button
            pill
            href="/contactus"
            class="btn-tekkis text-700-22 mt-3 button1_mobile"
            style="font-size: 18px; width: auto"
            >{{ $t("website.homepage.getstarted.contactbtn") }}
            <img
              class="img-fit-div"
              style="width: 15px"
              src="../assets/img/arrow-right-white.png"
              alt=""
          /></b-button>
        </b-col>
        <!-- <b-col lg="3" class="pl-lg-0">
          <b-button
            :href="`mailto:hello@tekkis.com.my`"
            pill
            class="text-700-22 btn-tekkis-inverse-bw button2_mobile"
            style="
              padding: 0.375rem 0rem;
              border-bottom-width: thin;
              line-height: 20px;
              font-size: 18px;
            "
          >
            Sign up
          </b-button>
        </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.text-1 {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
}

.button1-mobile {
  @media screen and (min-width: 576px) {
    padding: 5px 15px 5px 15px;
    margin-left: auto !important;
  }

  @media screen and (max-width: 576px) {
    padding: 5px 15px 5px 0px;
    margin-left: 0% !important;
  }
}

.button2-mobile {
  @media screen and (min-width: 576px) {
    margin-left: auto !important;
  }

  @media screen and (max-width: 576px) {
    margin-left: 0% !important;
  }
}
</style>
<script>
// import TekkisButtonEmail from "./buttons/TekkisButtonEmail.vue";

export default {
  components: {
    // TekkisButtonEmail,
  },
};
</script>
