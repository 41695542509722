<template>
  <div
    class="div-padding pt-lg-4 pt-5 mt-sm-4-custom"
    style="position: fixed; z-index: 2; width: 100%; background-color: white"
  >
    <b-navbar
      class="nav-bar px-lg-5 wrapper"
      style="z-index: 99; width: 100%"
      toggleable="lg"
      variant="info"
    >
      <b-navbar-brand to="/home" v-scroll-to="'#home-pg'" class="w-25"
        ><img
          id="header-logo"
          alt="Vue logo"
          src="../assets/img/tekkis-logo.webp"
        />
      </b-navbar-brand>

      <b-navbar-toggle
        target="nav-collapse"
        style="border: none"
      ></b-navbar-toggle>

      <b-collapse
        id="nav-collapse"
        class="pt-lg-4 w-100 mx-auto is-nav-mobile"
        is-nav
      >
        <b-navbar-nav class="text-500-16 mx-auto">
          <b-nav-item-dropdown text="Solutions" class="mx-2 my-1" left>
            <template slot="button-content">
              <router-link
                class="nav-text-link mr-1"
                to="/solutions-tverify"
                v-scroll-to="'#solutions-pg'"
              >
                {{ $t("website.header.products") }}</router-link
              >
            </template>
            <b-dropdown-item
              to="/solutions-tpay"
              v-scroll-to="'#solutions-tpay-pg'"
              >t-Pay</b-dropdown-item
            >
            <b-dropdown-item
              to="/solutions-tverify"
              v-scroll-to="'#solutions-pg'"
              >t-Verify</b-dropdown-item
            >
            <b-dropdown-item
              >Artificial intelligence (AI)
              <span class="custom-bagde"> Coming Soon</span></b-dropdown-item
            >
            <b-dropdown-item
              >Customer Experience
              <span class="custom-bagde"> Coming Soon</span></b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Use Cases" class="mx-2 my-1" left>
            <template slot="button-content">
              <router-link
                class="nav-text-link mr-1"
                to="/usecases-tverify"
                v-scroll-to="'#use-cases-pg'"
              >
                {{ $t("website.header.usecases") }}</router-link
              >
            </template>
            <span class="custom-heading">Payment - </span>
            <img
              class="tag-img mt-1 mb-2 pl-1"
              src="../assets/img/tpay-logo-1.png"
              alt=""
            />
            <!-- <b-dropdown-item
              to="/usecases-tpay"
              v-scroll-to="'#contact-list-pg'"
              >{{ $t("website.header.usecases4") }}</b-dropdown-item
            > -->

            <b-dropdown-item
              to="/usecases-tpay"
              v-scroll-to="'#payment-form-pg'"
              >{{ $t("website.header.usecases5") }}</b-dropdown-item
            >
            <b-dropdown-item
              to="/usecases-tpay"
              v-scroll-to="'#payment-link-pg'"
              >{{ $t("website.header.usecases6") }}</b-dropdown-item
            >
            <b-dropdown-item href="https://pay.tekkis.com.my/" target="_blank"
              >Store</b-dropdown-item
            >
            <br />
            <span class="custom-heading">Verification - </span>
            <img
              class="tag-img mt-1 mb-2 pl-1"
              src="../assets/img/tverify-logo-1.png"
              alt=""
            />
            <b-dropdown-item
              to="/usecases-tverify"
              v-scroll-to="'#identityverification'"
              >{{ $t("website.header.usecases1") }}</b-dropdown-item
            >
            <!-- <b-dropdown-item
              to="/usecases-tverify"
              v-scroll-to="'#identityfraud'"
              >{{ $t("website.header.usecases2") }}</b-dropdown-item
            > -->

            <b-dropdown-item
              to="/usecases-tverify"
              v-scroll-to="'#identityfraud'"
              >Business Verification
              <span class="custom-bagde"> Coming Soon</span></b-dropdown-item
            >
            <!-- <b-dropdown-item
              to="/usecases-tverify"
              v-scroll-to="'#useronboarding'"
              >{{ $t("website.header.usecases3") }}</b-dropdown-item
            > -->
            <div class="nav-item-new">
              <span class="custom-heading">Artificial intelligent (AI) </span>
              <span class="custom-bagde ml-3 ml-lg-0"> Coming Soon</span>
            </div>
            <div class="nav-item-new">
              <span class="custom-heading">Customer Experience </span>
              <span class="custom-bagde ml-3 ml-lg-0"> Coming Soon</span>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Company" class="mx-2 mt-1" left>
            <template slot="button-content">
              <router-link
                class="nav-text-link mr-1"
                to="/company"
                v-scroll-to="'#aboutus'"
              >
                {{ $t("website.header.company") }}</router-link
              >
            </template>
            <b-dropdown-item to="/company" v-scroll-to="'#aboutus'">{{
              $t("website.header.aboutus")
            }}</b-dropdown-item>
            <b-dropdown-item to="/ourteam">{{
              $t("website.header.ourteam")
            }}</b-dropdown-item>
            <b-dropdown-item to="/career">{{
              $t("website.header.career")
            }}</b-dropdown-item>
            <b-dropdown-item to="/contactus" v-scroll-to="'#contactus'">{{
              $t("website.header.contactus")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Pricing" class="mx-2 my-1" left>
            <template slot="button-content">
              <router-link
                class="nav-text-link mr-1"
                to="/pricing-tverify"
                v-scroll-to="'#pricing-pg'"
              >
                {{ $t("website.header.pricing") }}</router-link
              >
            </template>
            <b-dropdown-item to="/pricing-tpay" v-scroll-to="'#pricing-tpay-pg'"
              >t-Pay</b-dropdown-item
            >
            <b-dropdown-item to="/pricing-tverify" v-scroll-to="'#pricing-pg'"
              >t-Verify</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Resources" class="mx-2 my-1" left>
            <template slot="button-content">
              <router-link class="nav-text-link mr-1" to="#">
                Resources</router-link
              >
            </template>
            <b-dropdown-item href="https://blog.tekkis.com.my/"
              >Blog</b-dropdown-item
            >
            <b-dropdown-item to="/faq" v-scroll-to="'#faq-pg'"
              >FAQ</b-dropdown-item
            >
            <b-dropdown-item to="/news" v-scroll-to="'#news-pg'"
              >News</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-navbar-nav
            class="text-500-16 d-lg-none d-flex w-100 justify-content-start flex-row"
          >
            <!-- <div id="language" class="d-flex align-items-center px-1">
              <img :src="getPic()" alt="" />
              <b-nav-item-dropdown
                :text="this.$i18n.locale"
                class="text-right d-inline-block pl-2"
                right
              >
              </b-nav-item-dropdown>
              <label
                v-for="(item, index) in optionsLang"
                v-bind:key="index"
                class="langRadio py-1 m-0"
              >
                <input
                  type="radio"
                  v-model="$store.state.lang"
                  :value="item.value"
                  v-on:change="setLang(item.value)"
                  class="invisible"
                />
                {{ item.text }}
              </label>
            </div> -->

            <!-- <div id="language" class="d-flex align-items-center px-1"> -->

            <b-nav-item-dropdown
              :text="this.$i18n.locale"
              class="d-inline-block pl-2 w-100"
              left
            >
              <template slot="button-content">
                <img
                  :src="getPic()"
                  alt=""
                  style="height: 30px; height: 30px"
                  class="mr-2 text-left"
                />
                {{ this.$i18n.locale }}</template
              >

              <label
                v-for="(item, index) in optionsLang"
                v-bind:key="index"
                class="langRadio py-1 m-0"
                style="padding: 0.25rem 1.5rem"
                ><input
                  type="radio"
                  v-model="$store.state.lang"
                  :value="item.value"
                  v-on:change="setLang(item.value)"
                  class="d-none"
                />{{ item.text }}</label
              >
            </b-nav-item-dropdown>
            <!-- </div> -->
          </b-navbar-nav>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
      </b-collapse>
      <!-- <b-navbar-nav class="d-lg-block w-25 d-none"> </b-navbar-nav> -->
      <b-navbar-nav
        class="ml-auto text-500-16 d-lg-flex d-none w-25 justify-content-end"
      >
        <div id="language" class="d-flex align-items-center px-1">
          <img :src="getPic()" alt="" />
          <b-nav-item-dropdown
            :text="this.$i18n.locale"
            class="text-right d-inline-block"
            right
          >
            <label
              v-for="(item, index) in optionsLang"
              v-bind:key="index"
              class="langRadio py-1 m-0"
            >
              <input
                type="radio"
                v-model="$store.state.lang"
                :value="item.value"
                v-on:change="setLang(item.value)"
                class="invisible"
              />
              {{ item.text }}
            </label>
          </b-nav-item-dropdown>
        </div>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<style lang="scss">
@import "src/assets/styles/all.scss";
#language {
  border: 1px solid #dfdfdf;
  border-radius: 100px;
  width: fit-content;
}

#language img {
  height: 30px;
  width: 30px;
}
.langRadio {
  width: 100%;
  cursor: pointer;
}

.langRadio:hover {
  background-color: #e9ecef;
}

.langRadio:active {
  background-color: #007bff;
  color: white;
}

.tag-img {
  height: 30px;
}

@import "~@/assets/scss/vendors/bootstrap-vue/app";
#header-logo {
  width: 100px;
  height: auto;
}

.nav-text-link,
.nav-text-link:hover {
  text-decoration: none;
  color: #12070b;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; // remove the gap so it doesn't close
}

.fadeInUp {
  animation: fadeInUp 1s ease backwards;
}
@keyframes fadeInUp {
  0% {
    transform: translate(0px, 100px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}
.blog {
  margin: 0.35rem 0.45rem 0.65rem 0.45rem;
}
@media screen and (min-width: 992px) {
  .blog {
    padding: 0 1rem !important;
    margin: 0 !important;
  }
  .blog a {
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  .mt-sm-4-custom {
    margin-top: 1.5rem !important;
  }
  .is-nav-mobile {
    overflow: scroll;
    max-height: 80vh;
  }
}

.custom-bagde {
  @extend .badge;
  color: $purple-400;
  background-color: $purple-100;
  border: 1px solid $purple-400;
  font-weight: 100;
  @media screen and (max-width: 768px) {
    display: block;
    width: fit-content;
  }
}
.custom-heading {
  padding-left: 0.75rem;
  font-weight: 700;
  color: $pink-500;
}
.nav-item-new {
  @extend .mt-3;
  width: max-content;
  margin-right: 1rem;
}
</style>
<script>
export default {
  data() {
    return {
      optionsLang: [
        { text: "Bahasa Malaysia", value: "my" },
        { text: "English", value: "en" },
      ],
      langImg: require("../assets/img/" + this.$i18n.locale + ".png"),
    };
  },
  methods: {
    jumpPage(url) {
      window.location.href = "/" + url;
    },
    setLang(value) {
      this.$store.commit("setLang", value);
      this.$i18n.locale = value;
      localStorage.setItem("footmark-lang", value);
    },
    getPic() {
      return require("../assets/img/" + this.$i18n.locale + ".png");
    },
  },
};
</script>
