<template>
  <div class="div-padding" id="solution" style="background-color: #faf8f8">
    <b-container class="wrapper pt-3">
      <b-row>
        <b-col class="p-0">
          <div class="text-500-48 text-center mb-3 mt-2 pt-2">
            <h3>
              {{ $t("website.homepage.solution.title") }}
            </h3>
          </div>
          <div class="text-center align-left-mobile solution-p">
            {{ $t("website.homepage.solution.description") }}
          </div>
          <div class="pt-3 card-wrapper ml-auto mr-auto">
            <b-row>
              <b-col
                v-for="Card in Cards"
                :key="Card.title"
                md="4"
                class="mb-sm-5 pb-4 mb-2"
                ><Card :Card="Card" />
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Card from "./Card.vue";

export default {
  name: "Solution",
  components: {
    Card,
  },
  data() {
    return {
      Cards: [
        {
          title: this.$t("website.homepage.solution.titlePayment"),
          description: this.$t("website.homepage.solution.desc2"),
          image: "payments.webp",
          btntitle: this.$t("website.general.learnmorebtn"),
          link: "solutions-tpay",
          scroll: "'solutions-tpay-pg'",
          titleimg: "tpay-logo-1.png",
          istPay: true,
          commingSoon: false,
        },
        {
          title: this.$t("website.homepage.solution.titleVerification"),
          description: this.$t("website.homepage.solution.desc1"),
          image: "verification.webp",
          btntitle: this.$t("website.general.learnmorebtn"),
          scroll: "#solutions-pg",
          link: "solutions-tverify",
          titleimg: "verification",
          commingSoon: false,
        },
        {
          title: this.$t("website.homepage.solution.titleCustomerExperience"),
          description: this.$t("website.homepage.solution.desc3"),
          image: "customer.webp",
          btntitle: this.$t("website.general.learnmorebtn"),
          scroll: "#solutions-pg",
          link: "solutions-tverify",
          titleimg: "customer",
          commingSoon: true,
        },
      ],
    };
  },
};
</script>

<style scoped>
@media only screen and (min-width: 576px) {
  .solution-p {
    width: 60%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 426px) and (max-width: 1200px) {
  .solution-p {
    width: 80%;
    margin: 0 auto;
  }
}
</style>
