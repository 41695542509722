import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueScrollTo from "vue-scrollto";
// import ga from "vue-ga";
// import VueGtag from "vue-gtag";
import VueI18n from "vue-i18n";
import my from "./i18n/my";
import en from "./i18n/en";
import VueTyperPlugin from "vue-typer";
import VueCarousel from "vue-carousel";
import VueMeta from "vue-meta";
import mixpanel from "mixpanel-browser";
Vue.use(VueMeta);
Vue.use(VueCarousel);
Vue.use(VueTyperPlugin);
Vue.use(VueI18n);

// Vue.use(VueGtag, {
//   config: {
//     id: "G-NZQXSDF5L6",
//   },
//   includes: [
//     {
//       id: "AW-11066875332",
//     },
//   ],
// });

mixpanel.init("350ac3147e751747b2bdb2ee0ab9dea6");

let locale = "en";

if (localStorage.getItem("footmark-lang")) {
  locale = localStorage.getItem("footmark-lang");
  store.commit("setLang", locale);
} else {
  store.commit("setLang", locale);
}

const i18n = new VueI18n({
  locale: locale,
  messages: {
    my: my,
    en: en,
  },
});

Vue.config.productionTip = false;
//Under Prev
// ga(router, "UA-220140575-1");

// You can also pass in the default options
Vue.use(VueScrollTo, {
  el: "#app",
  container: "body",
  duration: 0,
  easing: "linear",
  offset: 0,
  lazy: false,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
