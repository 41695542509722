<template>
  <!-- <p>{{ this.data }}</p> -->
  <div
    class="pt-5 mt-sm-4-custom"
    style="position: fixed; z-index: 2; width: 100%; background-color: white"
  >
    <b-navbar
      class="nav-bar px-lg-5 wrapper"
      style="z-index: 99; width: 100%"
      toggleable="lg"
      variant="info"
    >
      <b-navbar-brand to="/home" v-scroll-to="'#home-pg'" class="w-25"
        ><img
          id="header-logo"
          alt="Vue logo"
          src="../assets/img/tekkis-logo.webp"
        />
      </b-navbar-brand>
      <b-navbar-nav
        v-if="showCover"
        class="ml-auto text-500-16 d-lg-flex d-none w-25 justify-content-end"
      >
        <button class="button-insurance" @click="scrollToElement">
          {{ $t("website.insurance.component.btn1") }}
        </button>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/styles/all.scss";

#language {
  border: 1px solid #dfdfdf;
  border-radius: 100px;
  width: fit-content;
}

#language img {
  height: 30px;
  width: 30px;
}

@keyframes fadeInUp {
  0% {
    transform: translate(0px, 100px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .mt-sm-4-custom {
    margin-top: 2rem !important;
  }
}

.button-insurance {
  display: inline-flex;
  padding: 10px 15px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: linear-gradient(
    49deg,
    #0f3078 0%,
    #3c84d8 54.69%,
    #7dc5fd 95.83%
  );

  /* Btn1 */
  text-align: center;
  font-family: Jost;
  font-size: 18px; // original 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.79px;
}

.btn2 {
  @include button2;
}
</style>
<script>
export default {
  props: {
    showCover: Boolean, // Define the prop with the expected data type (String in this case)
  },
  data() {
    return {
      optionsLang: [
        { text: "Bahasa Malaysia", value: "my" },
        { text: "English", value: "en" },
      ],
      langImg: require("../assets/img/" + this.$i18n.locale + ".png"),
    };
  },
  methods: {
    jumpPage(url) {
      window.location.href = "/" + url;
    },
    scrollToElement() {
      const targetElement = document.getElementById("cyberInsTarget");
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the element
      }
    },
    setLang(value) {
      this.$store.commit("setLang", value);
      this.$i18n.locale = value;
      localStorage.setItem("footmark-lang", value);
    },
    getPic() {
      return require("../assets/img/" + this.$i18n.locale + ".png");
    },
  },
};
</script>
