<template>
  <div class="media mt-2">
    <div class="text-center mt-5">
      <h4 class="color-text900">
        {{ $t("website.homepage.media.title") }}
      </h4>
      <div class="media-wrapper mt-5">
        <div class="d-flex flex-wrap col-12 justify-content-center">
          <img
            class="col-9 col-md-2 col-sm-5 mb-4"
            src="@/assets/img/media-featured/nst-logo.png"
            alt=""
          />

          <img
            class="col-9 col-md-2 col-sm-5 mb-4"
            src="@/assets/img/media-featured/tv3-logo.png"
            alt=""
          />

          <img
            class="col-9 col-md-2 col-sm-5 mb-4"
            src="@/assets/img/media-featured/fintech-logo.png"
            alt=""
          />

          <img
            class="col-9 col-md-2 col-sm-5 mb-4"
            src="@/assets/img/media-featured/soyacincau-logo.png"
            alt=""
          />

          <img
            class="col-9 col-md-2 col-sm-5 mb-4"
            src="@/assets/img/media-featured/bernama-logo.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "media",
};
</script>

<style scoped>
.media-wrapper img {
  filter: drop-shadow(0px 10px 25px rgba(0, 0, 0, 0.05));
}
</style>
